import React from 'react';

function CrossIcon() {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="18.4932" cy="18.4932" r="18.4932" fill="#1c1c1e" />
      <path
        d="M24.1026 12.8848L12.8828 24.1045M24.1026 24.1045L12.8828 12.8848"
        stroke="#B1B1B1"
        stroke-width="1.60274"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default CrossIcon;
